.slide-pane {
    display: flex;
    flex-direction: column;
    background: #fff;
    min-width: 100px;
    height: 100%;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.5s;
    will-change: transform;
    z-index: 999;
    width: 80% !important;
  }
  .slide-pane:focus {
    outline-style: none;
  }
  .slide-pane_from_right {
    margin-left: auto;
    transform: translateX(100%);
  }
  .slide-pane_from_right.content-after-open {
    transform: translateX(0%);
  }
  .slide-pane_from_right.content-before-close {
    transform: translateX(100%);
  }
  .slide-pane_from_left {
    margin-right: auto;
    transform: translateX(-100%);
  }
  .slide-pane_from_left.content-after-open {
    transform: translateX(0%);
  }
  .slide-pane_from_left.content-before-close {
    transform: translateX(-100%);
  }
  .slide-pane_from_bottom {
    height: 90vh;
    margin-top: 10vh;
    transform: translateY(100%);
  }
  .slide-pane_from_bottom.content-after-open {
    transform: translateY(0%);
  }
  .slide-pane_from_bottom.content-before-close {
    transform: translateY(100%);
  }
  .slide-pane__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 999;
  }
  .slide-pane__overlay.overlay-after-open {
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.5s;
    z-index: 99999;
  }
  .slide-pane__overlay.overlay-before-close {
    background-color: rgba(0, 0, 0, 0);
  }
  .slide-pane__header {
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    background: #fff;
    height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .slide-pane__title-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 32px;
    min-width: 0;
  }
  .slide-pane .slide-pane__title {
    font-size: 18px;
    font-weight: normal;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
  }
  .slide-pane__close {
    margin-left: 24px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
    color: #40a4f1;
  }
  .slide-pane__close svg {
    width: 12px;
    padding: 0;
    color: #40a4f1;
  }
  .slide-pane__content {
    position: relative;
    overflow-y: hidden;
    padding: 0px 0px 0px 0px;
    flex: 1 1 auto;
    background: #e7ebf2;
  }
  
  /* Safari 10.1+  */
  
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .slide-pane__content div {
        position: initial !important;
      }
    }
  }
  
  /* Safari 11+ */
  
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .slide-pane__content div {
        position: initial !important;
      }
    }
  }
  
  /* Safari 10.0 (not 10.1) but not on iOS */
  
  _::-webkit-:-webkit-full-screen:host:not(:root:root),
  .slide-pane__content div {
    position: initial !important;
  }
  
  /* Safari 7.1+ */
  
  _::-webkit-full-page-media,
  _:future,
  :root .slide-pane__content div {
    position: initial !important;
  }
  
  .slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
  }
  @media only screen and (max-width: 600px) {
    .slide-pane {
      width: 100% !important;
    }
    .slide-pane__content {
      padding: 2px 5px;
    }
  }
  