
.second-modal-container
    background-color: rgba(0, 0, 0, 0.6)
    width: 100vw
    height: 100vh
    position: fixed
    left: 0
    top: 0
    z-index: 1300
    pointer-events: unset
    opacity: 1
    display: none
    .second-modal-backdrop
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        height: auto
        margin: 48px
        display: flex
        justify-content: center
        .second-modal
            position: relative
            display: flex
            flex-direction: column
            left: 0
            top: 0
            width: 100%
            z-index: 999
            border-radius: 4px
            background-color: white
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6)
            max-height: calc(100% - 0px)
            max-width: 660px
            width: calc(100% - 64px)
            height: fit-content
            word-wrap: break-word
        .second-modal--medium
            max-width: 900px
        .second-modal--large
            max-width: 1920px
        .second-modal__loader
            position: absolute
            width: 100%
            height: 100%
            z-index: 200
            display: none
            text-align: center
        .second-modal__loader--active
            z-index: 200
            background-color: white
            opacity: 0.9
            display: block
        .second-modal__header,
        .second-modal__footer
            padding: 10px

        .second-modal__header
            display: flex
            justify-content: flex-end
            .second-modal__header-close-icon
                cursor: pointer
                padding-top: 5px
        .second-modal__content
            overflow-y: auto
            max-height: calc(100% - 0px)
            padding: 20px
            border-top: 1px solid rgba(0, 0, 0, 0.12)
            border-bottom: 1px solid rgba(0, 0, 0, 0.12)
.second-modal-container--active
  display: block
  animation: show 0.8s
  animation-fill-mode: forwards

